import { Typography } from 'lux/components';

import { TableCell, TableRow } from '../../tableComponents/tableComponents';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { useGetIsFirstDayOfProject } from 'hooks/useGetIsFirstDayOfProject/useGetIsFirstDayOfProject';
import { TeamMemberRow } from '../teamMemberRow/TeamMemberRow';

import * as styles from './WorkStatementRow.styles';
import { WorkStatementRowProps } from './WorkStatementRow.types';

export const WorkStatementRow = ({ workStatement }: WorkStatementRowProps) => {
  const { timesheetMonthDatesParsed: days } = useProjectTimesheet();

  const getIsFirstDayOfProject = useGetIsFirstDayOfProject();

  return (
    <>
      <TableRow>
        <TableCell sx={styles.workStatementCell(false)}>
          <Typography variant="buttonSmall" sx={styles.workStatementName}>
            {workStatement.statementNumber}
          </Typography>
        </TableCell>
        {days.map((day, idx) => {
          const { isProjectStartDayMarker } = getIsFirstDayOfProject(Number(day.number));

          return <TableCell key={idx} sx={styles.workStatementCell(isProjectStartDayMarker)} />;
        })}
      </TableRow>
      {workStatement.assignments.map((assignment) => (
        <TeamMemberRow key={assignment.employeeId} assignment={assignment} />
      ))}
    </>
  );
};
