import { TableBody } from '../tableComponents/tableComponents';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { useProjectStartDay } from 'hooks/useProjectStartDay/useProjectStartDay';

import { WorkStatementRow } from './workStatementRow/WorkStatementRow';
import * as styles from './TimesheetTableBody.styles';

export const TimesheetTableBody = () => {
  const { timesheetData: workStatements } = useProjectTimesheetData();
  const { projectStartDay, daysInMonth } = useProjectStartDay();

  return (
    <TableBody sx={styles.projectStartDayMarker(projectStartDay, daysInMonth)}>
      {workStatements.map((workStatement) => (
        <WorkStatementRow key={workStatement.id} workStatement={workStatement} />
      ))}
    </TableBody>
  );
};
