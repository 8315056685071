import { Stack } from '@mui/material';
import { Grid } from 'lux/components';

import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';
import { isPM } from 'utils/isPM';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { isTM } from 'utils/isTM';
import { ProjectCardWrapper } from 'app/project/projectCardWrapper/ProjectCardWrapper';

import { RateCardDetails } from './rateCardDetails/RateCardDetails';
import { ProjectDetails } from './projectDetails/ProjectDetails';
import { ClientDetails } from './clientDetails/ClientDetails';
import { ProjectSetup } from './projectSetup/ProjectSetup';
import { ProjectDescription } from './projectDescription/ProjectDescription';
import { PeopleInProject } from './peopleInProject/PeopleInProject';
import { ProjectLinks } from './projectLinks/ProjectLinks';

export const ProjectOverview = () => {
  const { projectDetails, allowProjectEdition } = useProjectDetails();
  const { id, client, stage, links } = projectDetails;
  const { data: user } = useCurrentUser();

  const shouldDisplayRateCards = () => {
    if (!user) {
      return false;
    }

    // TODO below condition is a hotfix and should be changed as part of task TSHLUX-1015, for further information see the mentioned task and contact task's reporter
    if (isPM(user.person.departmentCode) && !isTM(user.person.role) && user.person.employeeId !== 277) {
      const isUserAssignedAsPm = projectDetails.assignments.some(
        (assignment) =>
          assignment.person.employeeId === user.person.employeeId &&
          assignment.status !== AssignmentStatus.left &&
          isPM(assignment.role),
      );

      if (!isUserAssignedAsPm) {
        return false;
      }
    }

    return true;
  };

  return (
    <ProjectCardWrapper>
      <Grid spacing={0} container gutter={0}>
        <Grid item xs={12}>
          <ProjectDescription projectId={id} description={projectDetails.description} disabled={!allowProjectEdition} />
          <ProjectLinks projectId={id} links={links} />
        </Grid>
        <Grid spacing={0} container columnSpacing={3} gutter={0}>
          <Grid item xs={4}>
            <Stack gap={2} marginBottom={2}>
              {stage === ProjectStage.lead && <ProjectSetup projectData={projectDetails} />}
              <ProjectDetails projectData={projectDetails} disabled={!allowProjectEdition} />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack gap={2} marginBottom={2}>
              <PeopleInProject projectData={projectDetails} />
              <ClientDetails clientData={client} projectId={id} disabled={!allowProjectEdition} />
            </Stack>
          </Grid>
          {shouldDisplayRateCards() ? (
            <Grid item xs={4}>
              <RateCardDetails disabled={!allowProjectEdition} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </ProjectCardWrapper>
  );
};
