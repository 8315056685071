import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { DashboardCardProps } from 'ui/dashboardCard/DashboardCardProps.types';

import * as styles from './ProjectCardWrapper.styles';

export const ProjectCardWrapper = ({ children, sx, ...props }: DashboardCardProps) => (
  <DashboardCard sx={sx || { wrapper: styles.cardWrapper }} {...props}>
    {children}
  </DashboardCard>
);
