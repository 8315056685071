import { Styles } from 'styles/theme';

export const WORK_STATEMENT_DETAILS_WIDTH = 432;
export const WORK_STATEMENT_DETAILS_ANIMATION_DURATION = 300;

export const container: Styles = {
  position: 'relative',
};

export const wrapper = (isShrunk = false): Styles => ({
  border: 'none',
  width: isShrunk ? `calc(100% - ${WORK_STATEMENT_DETAILS_WIDTH}px)` : '100%',
  transition: `ease-in-out ${WORK_STATEMENT_DETAILS_ANIMATION_DURATION}ms`,
});

export const title: Styles = {
  display: 'block',
  color: 'text.secondary',
  fontWeight: 500,
  mb: 2,
};

export const ProjectBillingSummaryWrapperStyles: Styles = {
  flex: 1,
  backgroundColor: 'common.white',
  padding: 5,
  borderRadius: 0,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  borderBottomLeftRadius: 20,
  borderBottomRightRadius: 20,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'border.light',
};

export const moneyEmoji: Styles = {
  alignItems: 'center',
  fontSize: '1rem',
  mb: 1,

  '&.MuiPaper-root::before': {
    backgroundColor: 'unset',
  },

  '> .MuiAlert-icon': {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '1rem',
    aspectRatio: 1,
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: 2.5,
  },
};
