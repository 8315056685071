import { Tabs } from 'lux/components';
import { Box, Stack } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useAssignmentInfo } from 'hooks/useAssignmentInfo/useAssignmentInfo';
import { ProjectCardWrapper } from 'app/project/projectCardWrapper/ProjectCardWrapper';
import { ProjectBillingSummaryDataContextController } from '../../../context/projectBillingSummary/projectBillingSummaryDataContextController/ProjectBillingSummaryDataContextController';
import { ProjectBillingSummaryContextController } from '../../../context/projectBillingSummary/projectBillingSummaryContextController/ProjectBillingSummaryContextController';

import * as styles from './TeamDetails.styles';
import { AssignmentInfoCard } from './assignmentInfoCard/AssignmentInfoCard';
import { useTeamDetailsTabs } from './useTeamDetailsTabs/useTeamDetailsTabs';

export const TeamDetails = () => {
  const { formatMessage } = useLocale();
  const { projectDetails, allowProjectEdition } = useProjectDetails();
  const { id, stage, activeAssignments } = projectDetails;
  const { isOpen: isAssignmentInfoOpen } = useAssignmentInfo();

  const { activeTabIndex, onTabChange, tabs } = useTeamDetailsTabs({
    hasActiveAssignments: Boolean(activeAssignments),
    projectStage: stage,
  });

  return (
    <Stack direction="row" height="100%" spacing={3}>
      <ProjectCardWrapper title={formatMessage({ id: AppMessages['projectDetails.teamDetails.header'] })}>
        <Tabs>
          <Tabs.TabList value={activeTabIndex} onChange={onTabChange} sx={styles.tabList}>
            {tabs.map((tab) => (
              <tab.tab key={tab.key} projectId={id} />
            ))}
          </Tabs.TabList>
          {tabs.map((tab, index) => (
            <Tabs.TabPanel key={tab.key} value={activeTabIndex} index={index}>
              <tab.panel projectId={id} allowProjectEdition={allowProjectEdition} />
            </Tabs.TabPanel>
          ))}
        </Tabs>
      </ProjectCardWrapper>
      <ProjectBillingSummaryContextController>
        <ProjectBillingSummaryDataContextController>
          {isAssignmentInfoOpen && (
            <Box sx={{ width: 500 }}>
              <AssignmentInfoCard />
            </Box>
          )}
        </ProjectBillingSummaryDataContextController>
      </ProjectBillingSummaryContextController>
    </Stack>
  );
};
