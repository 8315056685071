import { Box } from '@mui/material';
import { Typography, Button } from 'lux/components';
import { ChevronLeftIcon, ChevronRightIcon } from 'lux/icons';

import {
  addMonthToDate,
  formatDate,
  getCurrentDayStartDate,
  isDateAfter,
  isDateBefore,
  isDateInSameMonth,
} from 'utils/dateUtils';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { isBeginningOfTimesheet } from '../../utils/isBeginningOfTimesheet';

import * as styles from './ProjectNavigationBar.styles';
import { ProjectNavigationBarProps } from './ProjectNavigationBar.types';

export const ProjectNavigationBar = ({
  selectedMonthStartDate = new Date(),
  onPreviousMonthClick = () => {},
  onNextMonthClick = () => {},
  onCurrentMonthClick = () => {},
  isNavPrevArrowDisabled = false,
  isNavNextArrowDisabled = false,
}: ProjectNavigationBarProps) => {
  const { formatMessage } = useLocale();
  const formattedMonthLabel = formatDate(selectedMonthStartDate, 'MMMM yyyy');
  const isSameMonth = isDateInSameMonth(getCurrentDayStartDate(), selectedMonthStartDate);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.actions}>
        <Button
          iconOnly
          variant="outlined"
          size="small"
          aria-label={formatMessage({ id: AppMessages['projectDetails.timesheet.aria.previousMonth'] })}
          disabled={
            isBeginningOfTimesheet(selectedMonthStartDate) ||
            (isNavPrevArrowDisabled && isDateBefore(addMonthToDate(selectedMonthStartDate), getCurrentDayStartDate()))
          }
          onClick={onPreviousMonthClick}
        >
          <ChevronLeftIcon />
        </Button>
        <Typography variant="h6" sx={styles.monthsTextContainer}>
          {formattedMonthLabel}
        </Typography>
        <Button
          iconOnly
          variant="outlined"
          size="small"
          aria-label={formatMessage({ id: AppMessages['projectDetails.timesheet.aria.nextMonth'] })}
          onClick={onNextMonthClick}
          disabled={
            isNavNextArrowDisabled && isDateAfter(addMonthToDate(selectedMonthStartDate), getCurrentDayStartDate())
          }
        >
          <ChevronRightIcon />
        </Button>
        <Button size="small" variant="outlined" onClick={onCurrentMonthClick} disabled={isSameMonth}>
          {formatMessage({ id: AppMessages['projectDetails.timesheet.button.currentMonth'] })}
        </Button>
      </Box>
    </Box>
  );
};
