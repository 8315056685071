import { useCallback, useState } from 'react';

import { Drawer } from '@mui/material';
import { ChevronLeftIcon, ChevronRightIcon, DollarIcon, EndDateIcon, InfoIcon, NoteIcon, TeamIcon } from 'lux/icons';
import { Button } from 'lux/components';

import { ProjectDetailsRoute } from 'routing/AppRoute.enum';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';

import * as styles from './Sidebar.styles';
import { SidebarButton } from './sidebarButton/SidebarButton';
import { SidebarHeader } from './sidebarHeader/SidebarHeader';
import { SidebarProps } from './Sidebar.types';

export const Sidebar = ({ pinned, togglePin }: SidebarProps) => {
  const { formatMessage } = useLocale();
  const { projectDetails } = useProjectDetails();
  const [open, setOpen] = useState(pinned || false);

  const openSidebar = useCallback(() => {
    if (!pinned) {
      setOpen(true);
    }
  }, [pinned]);

  const closeSidebar = useCallback(() => {
    if (!pinned) {
      setOpen(false);
    }
  }, [pinned]);

  return (
    <Drawer
      open
      hideBackdrop
      onMouseEnter={openSidebar}
      onMouseLeave={closeSidebar}
      sx={styles.drawer(open)}
      variant="temporary"
      elevation={0}
      ModalProps={{
        disableScrollLock: true,
        disableEnforceFocus: true,
      }}
    >
      <SidebarHeader title={projectDetails.name} description={projectDetails.client.name} iconOnly={!open} />
      <Button variant="text" size="small" iconOnly onClick={togglePin}>
        {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </Button>
      <SidebarButton
        to={ProjectDetailsRoute.overview}
        Icon={InfoIcon}
        label={formatMessage({ id: AppMessages['sidebar.menu.projectInfo'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ProjectDetailsRoute.team}
        Icon={TeamIcon}
        label={formatMessage({ id: AppMessages['sidebar.menu.team'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ProjectDetailsRoute.history}
        Icon={EndDateIcon}
        label={formatMessage({ id: AppMessages['sidebar.menu.history'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ProjectDetailsRoute.timesheet}
        Icon={NoteIcon}
        label={formatMessage({ id: AppMessages['sidebar.menu.timesheet'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ProjectDetailsRoute.summary}
        Icon={DollarIcon}
        label={formatMessage({ id: AppMessages['sidebar.menu.billing'] })}
        iconOnly={!open}
      />
    </Drawer>
  );
};
