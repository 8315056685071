import { Box, Stack } from '@mui/material';
import { Avatar, Badge, Grid, Typography, DataDisplay } from 'lux/components';

import { DashboardSideCard } from 'ui/dashboardSideCard/DashboardSideCard';
import { useAssignmentInfo } from 'hooks/useAssignmentInfo/useAssignmentInfo';
import { AssignmentMenuButton } from '../tabAssigned/assignmentMenuButton/AssignmentMenuButton';
import { Loader } from 'ui/loader/Loader';
import { formatRoleName, formatSeniorityName } from 'utils/stringUtils';
import { AssignmentStatusBadge } from 'ui/assignmentStatusBadge/AssignmentStatusBadge';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { fteToHumanValue } from 'app/need/utils';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { EmojiBadges } from 'ui/emojiBadges/EmojiBadges';
import { OverflowTooltipTypography } from 'ui/overflowTooltipTypography/OverflowTooltipTypography';

import * as styles from './AssignmentInfoCard.styles';
import { AssignmentExtensionDetails } from './assignmentExtensionDetails/AssignmentExtensionDetails';

export const AssignmentInfoCard = () => {
  const { assignment, setClose, projectAssignments } = useAssignmentInfo();
  const { projectDetails } = useProjectDetails();
  const { formatMessage } = useLocale();

  if (!assignment) {
    return (
      <DashboardSideCard onClose={setClose}>
        <Loader fullHeight={false} />
      </DashboardSideCard>
    );
  }

  return (
    <DashboardSideCard
      onClose={setClose}
      additionalHeaderButton={
        <AssignmentMenuButton assignmentData={assignment} projectAssignments={projectAssignments} />
      }
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 1.5 }}>
        <Stack gap={1.5} direction="row">
          <Avatar
            image={assignment.person.picture}
            size="lg"
            alt={`${assignment.person.firstName} ${assignment.person.lastName}`}
          />
          <Stack justifyContent="center">
            <Stack direction="row" gap={1} alignItems="center">
              <OverflowTooltipTypography
                text={`${assignment.person.firstName} ${assignment.person.lastName}`}
                variant="body1"
              />
              <EmojiBadges
                isLeader={assignment.isLeader}
                employmentType={assignment.person.employmentType}
                proposalCount={assignment.person.proposalCount}
                employedTo={assignment.person.employedTo}
                absences={assignment.person.absences}
                assignment={assignment}
              />
            </Stack>
            <Typography color="text.secondary" variant="body2">
              {formatRoleName(assignment.role, assignment.seniority)}
            </Typography>
          </Stack>
        </Stack>
        <AssignmentStatusBadge status={assignment.status} />
      </Stack>
      <Box marginBottom={1.5}>
        {assignment.person.skills && (
          <Stack gap={0.5} direction="row" flexWrap="wrap" marginBottom={1.5}>
            {assignment.person.skills.map(({ id, name }) => (
              <Badge isStatic key={id} color="gray" badgeContent={name} />
            ))}
          </Stack>
        )}
      </Box>
      <Typography variant="caption" component={Box} marginBottom={1.5}>
        {formatMessage({ id: AppMessages['card.startDate'] }, { date: assignment.startDate })}
      </Typography>
      {assignment.extensionDate && (
        <AssignmentExtensionDetails date={assignment.extensionDate} assignmentId={assignment.id} />
      )}
      <Box sx={styles.detailsTab}>
        <Grid container item>
          <Grid item xs={6} display="flex" flexDirection="column" gap={3}>
            <DataDisplay header={formatMessage({ id: AppMessages['card.details.seniority'] })}>
              {formatSeniorityName(assignment.seniority) || '-'}
            </DataDisplay>
            <DataDisplay header={formatMessage({ id: AppMessages['card.details.assignedFrom'] })}>
              {assignment.startDate}
            </DataDisplay>
            <DataDisplay header={formatMessage({ id: AppMessages['card.details.fte'] })}>
              {formatMessage({ id: AppMessages[fteToHumanValue(assignment.fte)] }, { fte: assignment.fte })}
            </DataDisplay>
            {projectDetails.plannedExtension && (
              <DataDisplay header={formatMessage({ id: AppMessages['card.details.extensionDate'] })}>
                {assignment.extensionDate || '-'}
              </DataDisplay>
            )}
          </Grid>
          <Grid item xs={6} display="flex" flexDirection="column" gap={3}>
            <DataDisplay header={formatMessage({ id: AppMessages['card.details.role'] })}>
              {assignment.role}
            </DataDisplay>
            <DataDisplay header={formatMessage({ id: AppMessages['card.details.assignedTo'] })}>
              {assignment.endDate || '-'}
            </DataDisplay>
            <DataDisplay header={formatMessage({ id: AppMessages['card.details.rate'] })}>
              <Stack direction="row" justifyContent="space-between">
                <Box>
                  <Typography variant="caption">{assignment.effectiveRateModifier ?? '-'}</Typography>
                  <Typography fontWeight={600} color="text.secondary" variant="caption">
                    &nbsp;{projectDetails.currentRateCard.currency}
                  </Typography>
                </Box>
                {assignment.isCustomRate && (
                  <Box>
                    <Badge
                      isStatic
                      color="gray"
                      badgeContent={formatMessage({ id: AppMessages['card.details.customRate'] })}
                    />
                  </Box>
                )}
              </Stack>
            </DataDisplay>
          </Grid>
        </Grid>
      </Box>
    </DashboardSideCard>
  );
};
