import { TimesheetTableHead } from './timesheetTableHead/TimesheetTableHead';
import { TimesheetTableBody } from './timesheetTableBody/TimesheetTableBody';
import { Table, TableWrapper } from './tableComponents/tableComponents';

export const TimesheetTable = () => (
  <TableWrapper>
    <Table>
      <TimesheetTableHead />
      <TimesheetTableBody />
    </Table>
  </TableWrapper>
);
