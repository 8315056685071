import { useState } from 'react';

import { Button, Tooltip, Typography } from 'lux/components';
import { Stack } from '@mui/system';
import { Box } from '@mui/material';
import { AddIcon } from 'lux/icons';

import { useBillingSummaryPrice } from 'hooks/useBillingSummaryPrice/useBillingSummaryPrice';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { BoxWithSlideInActions } from 'ui/boxWithSlideInActions/BoxWithSlideInActions';
import { Translation } from 'ui/translation/Translation';
import { useDialog } from 'hooks/useDialog/useDialog';
import { CostSummary } from '../../projectInvoices/costSummary/CostSummary';
import { AddDiscountModal } from '../../additionalValuesModals/addDiscountModal/AddDiscountModal';
import { EditDiscountModal } from '../../additionalValuesModals/editDiscountModal/EditDiscountModal';
import { WorkStatementCharge, WorkStatementDiscount } from 'api/types/BillingSummary.types';
import { AddChargeModal } from '../../additionalValuesModals/addChargeModal/AddChargeModal';
import { EditChargeModal } from '../../additionalValuesModals/editChargeModal/EditChargeModal';
import { useDeleteCharge } from 'hooks/useDeleteCharge/useDeleteCharge';
import { useDeleteDiscount } from 'hooks/useDeleteDiscount/useDeleteDiscount';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { isInvoiceModifiable } from 'utils/is-invoice-modifiable';
import { hideInPdf } from 'app/project/projectReport/ProjectReport.styles';

import { WorkStatementSummaryProps } from './WorkStatementSummary.types';
import * as styles from './WorkStatementSummary.styles';

export const WorkStatementSummary = ({ workStatement, billingCycle }: WorkStatementSummaryProps) => {
  const { formatPrice } = useBillingSummaryPrice();
  const { isReport } = useProjectBillingSummaryData();
  const { formatMessage } = useLocale();
  const [selectedItem, setSelectedItem] = useState<null | string>(null);
  const hasDiscountsOrCharges = workStatement.discounts.length > 0 || workStatement.charges.length > 0;
  const { mutate: deleteCharge, isLoading: deleteChargeLoading } = useDeleteCharge(workStatement.id);
  const { mutate: deleteDiscount, isLoading: deleteDiscountLoading } = useDeleteDiscount(workStatement.id);

  const {
    isOpen: isAddDiscountModalOpen,
    setOpen: setAddDiscountModalOpen,
    setClose: setAddDiscountModalClose,
  } = useDialog();
  const {
    isOpen: isAddChargeModalOpen,
    setOpen: setAddChargeModalOpen,
    setClose: setAddChargeModalClose,
  } = useDialog();
  const {
    isOpen: isEditDiscountModalOpen,
    setOpen: setEditDiscountModalOpen,
    setClose: setEditDiscountModalClose,
  } = useDialog();
  const {
    isOpen: isEditChargeModalOpen,
    setOpen: setEditChargeModalOpen,
    setClose: setEditChargeModalClose,
  } = useDialog();

  const closeModal = () => {
    setEditDiscountModalClose();
    setEditChargeModalClose();
    setSelectedItem(null);
  };

  const handleEditDiscount = (id: string) => {
    setSelectedItem(id);
    setEditDiscountModalOpen();
  };
  const handleEditCharge = (id: string) => {
    setSelectedItem(id);
    setEditChargeModalOpen();
  };
  const handleDeleteDiscount = (id: string) => {
    if (!billingCycle || !workStatement.invoice) return;

    deleteDiscount({
      discountId: id,
      billingCycleId: billingCycle.id,
      invoiceId: workStatement.invoice.id,
    });
  };
  const handleDeleteCharge = (id: string) => {
    if (!billingCycle || !workStatement.invoice) return;

    deleteCharge({
      chargeId: id,
      billingCycleId: billingCycle.id,
      invoiceId: workStatement.invoice.id,
    });
  };

  const disableActions = !isInvoiceModifiable(workStatement.invoice?.status) || isReport;
  const tooltipTitle = disableActions
    ? formatMessage({ id: AppMessages['projectDetails.billingSummary.workStatementDetails.tooltip'] })
    : undefined;

  return (
    <Stack sx={styles.container}>
      <Stack sx={styles.subtotalWrapper}>
        <Stack sx={styles.actionButtons}>
          <Tooltip title={tooltipTitle} placement="top" arrow>
            <Box>
              <Button
                variant="outlinedGray"
                size="small"
                startIcon={<AddIcon />}
                onClick={setAddChargeModalOpen}
                disabled={disableActions}
                sx={hideInPdf}
              >
                <Translation id="projectDetauls.billingSummary.workStatementDetails.addCustomValue" />
              </Button>
            </Box>
          </Tooltip>
          <Tooltip title={tooltipTitle} placement="top" arrow>
            <Box>
              <Button
                variant="outlinedGray"
                size="small"
                startIcon={<AddIcon />}
                onClick={setAddDiscountModalOpen}
                disabled={disableActions}
                sx={hideInPdf}
              >
                <Translation id="projectDetauls.billingSummary.workStatementDetails.addDiscount" />
              </Button>
            </Box>
          </Tooltip>
        </Stack>
        <CostSummary
          label="projectDetauls.billingSummary.workStatementDetails.subtotal"
          amount={workStatement.subTotal}
          variant="subtitle1"
        />
      </Stack>
      {hasDiscountsOrCharges && (
        <Typography sx={styles.title}>
          <Translation id="projectDetauls.billingSummary.workStatementDetails.discountsAndCustomValues" />
        </Typography>
      )}

      <Stack sx={styles.boxesWrapper}>
        {workStatement.charges.map((charge) => (
          <BoxWithSlideInActions
            key={charge.id}
            value={`+ ${formatPrice(charge.amount)}`}
            label={charge.label}
            onEdit={() => handleEditCharge(charge.id)}
            onDelete={() => handleDeleteCharge(charge.id)}
            isDeleting={deleteChargeLoading}
            isDisabled={disableActions}
          />
        ))}

        {workStatement.discounts.map((discount) => (
          <BoxWithSlideInActions
            key={discount.id}
            value={`- ${formatPrice(discount.amount)}`}
            label={discount.label}
            onEdit={() => handleEditDiscount(discount.id)}
            onDelete={() => handleDeleteDiscount(discount.id)}
            isDeleting={deleteDiscountLoading}
            isDisabled={disableActions}
          />
        ))}
      </Stack>
      <CostSummary
        label="projectDetauls.billingSummary.workStatementDetails.totalAmount"
        amount={workStatement.total}
      />

      {billingCycle && workStatement.invoice && (
        <>
          <AddDiscountModal
            open={isAddDiscountModalOpen}
            onClose={setAddDiscountModalClose}
            billingCycleId={billingCycle.id}
            workStatementId={workStatement.id}
            invoiceId={workStatement.invoice.id}
          />
          <AddChargeModal
            open={isAddChargeModalOpen}
            onClose={setAddChargeModalClose}
            billingCycleId={billingCycle.id}
            workStatementId={workStatement.id}
            invoiceId={workStatement.invoice.id}
          />
          {selectedItem && (
            <>
              <EditChargeModal
                open={isEditChargeModalOpen}
                onClose={closeModal}
                charge={workStatement.charges.find((charge) => charge.id === selectedItem) as WorkStatementCharge}
                billingCycleId={billingCycle.id}
                workStatementId={workStatement.id}
                invoiceId={workStatement.invoice.id}
              />
              <EditDiscountModal
                open={isEditDiscountModalOpen}
                onClose={closeModal}
                discount={
                  workStatement.discounts.find((discount) => discount.id === selectedItem) as WorkStatementDiscount
                }
                billingCycleId={billingCycle.id}
                workStatementId={workStatement.id}
                invoiceId={workStatement.invoice.id}
              />
            </>
          )}
        </>
      )}
    </Stack>
  );
};
