import { HourType } from './HourType.enum';

export type BillingCycleStatus =
  | 'created'
  | 'ongoing'
  | 'in_review'
  | 'approved_by_pm'
  | 'internal_billing'
  | 'approved'
  | 'done';

export type BillingCycle = {
  id: string;
  startDate: string;
  endDate: string;
  status: BillingCycleStatus;
  projectId: string;
};

export enum InvoiceStatusEnum {
  created = 'created',
  approved_by_pm = 'approved_by_pm',
  to_correct = 'to_correct',
  internal_billing = 'internal_billing',
  invoice_issued = 'invoice_issued',
  sent = 'sent',
  paid = 'paid',
  error = 'error',
}

export type InvoiceStatus =
  | InvoiceStatusEnum.created
  | InvoiceStatusEnum.approved_by_pm
  | InvoiceStatusEnum.to_correct
  | InvoiceStatusEnum.internal_billing
  | InvoiceStatusEnum.invoice_issued
  | InvoiceStatusEnum.sent
  | InvoiceStatusEnum.paid
  | InvoiceStatusEnum.error;

export type Invoice = {
  id: string;
  projectId: string;
  number: string;
  issueDate: string;
  saleDate: string;
  paymentDate: string;
  dueDate: string;
  status: InvoiceStatus;
  note: string | null;
};

export enum DepositStatus {
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  PAID = 'paid',
  OVERDUE = 'overdue',
  RETURNED = 'returned',
  CANCELED = 'canceled',
}

export type Deposit = {
  id: string;
  amount: number;
  paymentDue: string;
  status: DepositStatus;
};

export type WorkStatement = {
  id: string;
  statementNumber: string;
  startDate: string;
  endDate: string;
  deposits: Deposit[];
  comments: {}[];
  discounts: WorkStatementDiscount[];
  charges: WorkStatementCharge[];
  subTotal: number;
  total: number;
  assignments: WorkStatementAssignment[];
  roles?: WorkStatementRole[];
  invoice?: Invoice;
  note?: string;
  paymentPeriod?: number;
};

export type WorkStatementCharge = {
  id: string;
  label: string;
  amount: number;
  startDate: Date;
  endDate: Date;
};
export type WorkStatementDiscount = WorkStatementCharge;

export type WorkStatementTeamMemberHour = {
  amount: number;
  ratePercentage: number;
  total: number;
  rateValue: number;
  type: HourType;
};

export type WorkStatementAssignment = {
  firstName: string;
  lastName: string;
  picture: string;
  role: string;
  hours: WorkStatementTeamMemberHour[];
  total: number;
  employeeId: number;
};

export type WorkStatementRole = {
  name: string;
  hours: WorkStatementTeamMemberHour[];
  total: number;
};

export enum BillingSummaryGroupBy {
  MEMBERS = 'members',
  ROLES = 'roles',
}
