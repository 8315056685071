import { Box } from '@mui/system';
import { Typography } from 'lux/components';

import { Translation as TranslationKey } from 'i18n/messages';
import { ReactComponent as EmptyBillingInfo } from 'assets/images/empty-billing-info.svg';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { Translation } from 'ui/translation/Translation';
import { ProjectStage } from 'api/types/ProjectStage.enum';

import * as styles from './BillingSummaryEmptyState.styles';

export const BillingSummaryEmptyState = () => {
  const { projectDetails } = useProjectDetails();
  const isProjectStarted = projectDetails.stage === ProjectStage.lead;
  const isProjectClosed = projectDetails.stage === ProjectStage.closed;

  const emptyStateTextTranslation: TranslationKey = (() => {
    if (isProjectStarted) {
      return 'projectDetails.billingSummary.empty.lead';
    }

    if (isProjectClosed) {
      return 'projectDetails.billingSummary.empty.closed';
    }

    return 'projectDetails.billingSummary.empty.noData';
  })();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.imageWrapper}>
        <EmptyBillingInfo />
      </Box>
      <Typography variant="h6" sx={styles.text}>
        <Translation id={emptyStateTextTranslation} />
      </Typography>
    </Box>
  );
};
