import { useMemo } from 'react';

import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';
import { AutocompleteStack } from 'ui/autocompleteStack/AutocompleteStack';
import { useClients } from 'hooks/useClients/useClients';

import { ClientNameFilterProps } from './ClientNameFilter.types';

export const ClientNameFilter = ({ variant }: ClientNameFilterProps) => {
  const {
    filters: { clientName },
    clearFilter,
    setFilterValue,
  } = useProjectsFilters();
  const { formatMessage } = useLocale();
  const { data: clients = [] } = useClients();

  const handleClearClick = () => {
    clearFilter('clientName');
  };

  const handleFilterChange = (value: string[]) => {
    setFilterValue('clientName', value);
  };

  const clientsNames = useMemo(() => (clients ? clients.map((client) => client.name) : []), [clients]);

  return (
    <Filter
      variant={variant}
      label={formatMessage({ id: AppMessages['projects.filters.client.name.label'] })}
      popoverTitle={formatMessage({ id: AppMessages['projects.filters.client.name.label'] })}
      popoverActionLabel={formatMessage({ id: AppMessages['filters.clear'] })}
      valueSelected={clientName.length > 0}
      selectedCount={clientName.length}
      onPopoverActionClick={handleClearClick}
      shouldStretch
    >
      <AutocompleteStack
        label={formatMessage({ id: AppMessages['projects.filters.client.name.label'] })}
        values={clientName}
        dictionary={clientsNames}
        onFieldsChange={handleFilterChange}
        buttonLabel={formatMessage({ id: AppMessages['projects.filters.client.name.actionBtn'] })}
      />
    </Filter>
  );
};
