import { useQueryClient } from '@tanstack/react-query';

import { updateClientMutation } from 'api/actions/updateClient/updateClientActions';
import { clientsKeys, projectsKeys } from 'utils/queryKeys';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useUpdateClient = (projectId: string, clientId: string) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  return useMutationDomainError(['updateClientMutation'], updateClientMutation(clientId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(clientsKeys.allClients);
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.updateClient.error'] }),
  });
};
