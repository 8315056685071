import { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { Slide, SxProps, Typography } from '@mui/material';
import { Button } from 'lux/components';
import { EditIcon } from 'lux/icons';

import { DashboardSideCard } from 'ui/dashboardSideCard/DashboardSideCard';
import { WORK_STATEMENT_DETAILS_ANIMATION_DURATION } from '../ProjectBillingSummary.styles';
import { Translation } from 'ui/translation/Translation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { AppMessages } from 'i18n/messages';
import { BillingSummaryActions } from '../billingSummaryActions/BillingSummaryActions';
import { LoadingSpinner } from 'app/project/projectTimesheet/loadingSpinner/LoadingSpinner';
import { hideInPdf } from 'app/project/projectReport/ProjectReport.styles';

import * as styles from './WorkStatementDetailsSideCard.styles';
import { WorkStatementDetailsSideCardProps } from './WorkStatementDetailsSideCard.types';
import { CurrentBillingCycle } from './currentBillingCycle/CurrentBillingCycle';
import { InvoiceDetails } from './invoiceDetails/InvoiceDetails';
import { GeneralInformation } from './generalInformation/GeneralInformation';
import { Deposits } from './deposits/Deposits';

const TIMEOUT = {
  enter: WORK_STATEMENT_DETAILS_ANIMATION_DURATION * 1.1,
  exit: WORK_STATEMENT_DETAILS_ANIMATION_DURATION,
} as const;
const EASING = {
  enter: 'ease-in-out',
  exit: 'ease-in-out',
} as const;

export const WorkStatementDetailsSideCard = ({
  workStatement,
  billingCycle,
  showWorkStatementSnapshot,
  handleClose,
  isOpen,
  openEditOrder,
}: WorkStatementDetailsSideCardProps) => {
  const [stickyStyles, setStickyStyles] = useState<SxProps>({});

  const { formatMessage } = useLocale();
  const { isReport, isInvoiceEditBlocked } = useProjectBillingSummaryData();

  useEffect(() => {
    let timeout: number;

    if (isOpen) {
      timeout = window.setTimeout(() => {
        setStickyStyles(styles.sticky);
      }, 300);
    } else {
      setStickyStyles({});
    }

    return () => clearTimeout(timeout);
  }, [isOpen]);

  if (!workStatement) return null;

  return (
    <Slide in={isOpen} direction="left" timeout={TIMEOUT} easing={EASING} mountOnEnter unmountOnExit>
      <Box sx={{ ...styles.wrapper, ...stickyStyles, ...styles.exitButton(isReport) } as SxProps}>
        <Box sx={styles.sticky}>
          <DashboardSideCard
            onClose={handleClose}
            additionalHeaderButton={
              !isReport ? (
                <Button
                  onClick={openEditOrder}
                  size="medium"
                  variant="outlined"
                  startIcon={<EditIcon />}
                  sx={hideInPdf}
                  disabled={isInvoiceEditBlocked || showWorkStatementSnapshot}
                >
                  {formatMessage({ id: AppMessages['projectDetails.billingSummary.workStatementDetails.button.edit'] })}
                </Button>
              ) : null
            }
          >
            {workStatement ? (
              <>
                <Typography variant="h6" sx={styles.workStatementTitle}>
                  <Translation id="projectDetails.billingSummary.workStatement" /> {workStatement.statementNumber}
                </Typography>
                <Box sx={styles.detailsWrapper}>
                  <CurrentBillingCycle billingCycle={billingCycle} workStatement={workStatement} />
                  {billingCycle && workStatement.invoice && !isReport && (
                    <Box sx={styles.actions}>
                      <BillingSummaryActions billingCycle={billingCycle} invoice={workStatement.invoice} />
                    </Box>
                  )}
                  {workStatement.invoice && <InvoiceDetails invoice={workStatement.invoice} />}
                  <GeneralInformation workStatement={workStatement} />
                  <Deposits workStatement={workStatement} deposits={workStatement.deposits} />
                </Box>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </DashboardSideCard>
        </Box>
      </Box>
    </Slide>
  );
};
