import { Box } from '@mui/material';
import { Typography, Button } from 'lux/components';

import * as styles from './PageHeader.styles';
import { PageHeaderProps } from './PageHeader.types';

export const PageHeader = ({
  title,
  actionTitle,
  icon,
  size = 'medium',
  variant = 'contained',
  color = 'primary',
  onClick = () => {},
}: PageHeaderProps) => (
  <Box data-cy="page-header" sx={styles.pageHeader}>
    <Typography variant="h4">{title}</Typography>
    {actionTitle && (
      <Button data-cy="page-header_btn" onClick={onClick} size={size} variant={variant} color={color} startIcon={icon}>
        {actionTitle}
      </Button>
    )}
  </Box>
);
