import { useEffect, useState, useRef } from 'react';

import { Box, Stack } from '@mui/material';
import { Button, Typography } from 'lux/components';
import { useReactToPrint } from 'react-to-print';
import { DownloadIcon } from 'lux/icons';

import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { Translation } from 'ui/translation/Translation';
import { WorkStatement } from 'api/types/BillingSummary.types';
import { ProjectCardWrapper } from 'app/project/projectCardWrapper/ProjectCardWrapper';
import { BillingSummaryEmptyState } from '../projectBillingSummary/billingSummaryEmptyState/BillingSummaryEmptyState';
import { BillingSummaryWorkStatementSkeleton } from '../projectBillingSummary/billingSummaryWorkStatement/BillingSummaryWorkStatement.skeleton';
import { BillingSummaryWorkStatement } from '../projectBillingSummary/billingSummaryWorkStatement/BillingSummaryWorkStatement';
import { WorkStatementDetailsSideCard } from '../projectBillingSummary/workStatementDetailsSideCard/WorkStatementDetailsSideCard';
import { ProjectTimesheetSkeleton } from '../projectTimesheet/projectTimesheetSkeleton/ProjectTimesheetSkeleton';
import { TimesheetTable } from '../projectTimesheet/timesheetTable/TimesheetTable';
import { TimesheetEmptyState } from '../projectTimesheet/timesheetEmptyState/TimesheetEmptyState';

import * as styles from './ProjectReport.styles';
import { TshBanner } from './tshBanner/TshBanner';
import { ProjectRaportWrapperStyles } from './ProjectReport.styles';

export const ProjectReport = () => {
  const [selectedWorkStatement, setSelectedWorkStatement] = useState<WorkStatement | null>(null);
  const [isWorkStatementDetailsSideCardOpen, setIsWorkStatementDetailsSideCardOpen] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const mainContainerRef = useRef<HTMLDivElement>(null);

  const { isLoadingBillingSummaryData, workStatements, billingCycle, showWorkStatementSnapshot } =
    useProjectBillingSummaryData();

  const { isLoadingTimesheetData } = useProjectTimesheetData();

  const handlePrint = useReactToPrint({
    content: () => (mainContainerRef.current ? mainContainerRef.current : null),
  });

  const onPrintClick = () => {
    setIsPrinting(true);

    // This solution is a little hack to hide print button during making print snapshot. Current any of available methods in useReactToPrint don't solve this issue
    setTimeout(() => {
      handlePrint();
      setIsPrinting(false);
    }, 0);
  };

  const isMissingWorkStatements = workStatements.length === 0;

  const handleSetSelectedWorkStatement = (workStatement: WorkStatement) => {
    setSelectedWorkStatement(workStatement);
    setIsWorkStatementDetailsSideCardOpen(true);
  };

  useEffect(() => {
    if (setSelectedWorkStatement) {
      setSelectedWorkStatement(
        workStatements.find((workStatement) => workStatement.id === selectedWorkStatement?.id) ||
          workStatements?.[0] ||
          null,
      );
    }
  }, [workStatements]);

  return (
    <Stack ref={mainContainerRef} sx={styles.container} spacing={3}>
      <Stack sx={styles.bannerContainer}>
        <TshBanner
          workStatementNumber={selectedWorkStatement?.id}
          workStatementStartDate={selectedWorkStatement?.startDate}
        />
      </Stack>
      {!isPrinting && (
        <Stack width={'100%'} flex={1} alignItems={'flex-end'}>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            sx={styles.pdfButton}
            onClick={onPrintClick}
            startIcon={<DownloadIcon />}
          >
            <Translation id="projectDetails.billingSummary.export" />
          </Button>
        </Stack>
      )}
      <Stack direction="row" spacing={3} sx={styles.mainContainer}>
        <Box sx={styles.wrapper(isWorkStatementDetailsSideCardOpen)}>
          <Stack spacing={4} width="100%" height="100%" flex={1} position="relative">
            <ProjectCardWrapper
              sx={{
                wrapper: ProjectRaportWrapperStyles,
              }}
            >
              <Stack width={'100%'} display={'flex'} alignItems={'flex-end'}></Stack>
              <Stack sx={styles.billingSummaryTitleContainer}>
                <Typography sx={styles.title}>
                  <Translation id="projectDetails.billingSummary" />
                </Typography>
              </Stack>
              {isLoadingBillingSummaryData && <BillingSummaryWorkStatementSkeleton />}
              {!isLoadingBillingSummaryData && isMissingWorkStatements && <BillingSummaryEmptyState />}
              {workStatements.map((workStatement) => (
                <BillingSummaryWorkStatement
                  key={workStatement.id}
                  workStatement={workStatement}
                  billingCycle={billingCycle}
                  onClick={handleSetSelectedWorkStatement}
                />
              ))}
            </ProjectCardWrapper>
          </Stack>
        </Box>

        <WorkStatementDetailsSideCard
          workStatement={selectedWorkStatement}
          showWorkStatementSnapshot={showWorkStatementSnapshot}
          billingCycle={billingCycle}
          handleClose={() => null}
          isOpen={true}
          openEditOrder={() => null}
        />
      </Stack>
      <ProjectCardWrapper sx={{ wrapper: styles.timesheetWrapper }}>
        <Box sx={styles.titleContainer}>
          <Typography sx={styles.timesheetTitle}>
            <Translation id="projectDetails.timesheet.title" />
          </Typography>
        </Box>
        {isLoadingTimesheetData && <ProjectTimesheetSkeleton />}
        {!isLoadingTimesheetData && (workStatements.length ? <TimesheetTable /> : <TimesheetEmptyState />)}
      </ProjectCardWrapper>
    </Stack>
  );
};
