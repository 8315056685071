import { useState } from 'react';

import { Stack } from '@mui/material';
import { Accordion } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ClientDetailsProps } from 'app/project/projectOverview/clientDetails/ClientDetails.types';
import { useUpdateClient } from 'hooks/useUpdateClient/useUpdateClient';
import { TextFieldEdit } from 'ui/form/inlineEdit/textFieldEdit/TextFieldEdit';
import { MultipleAutocompleteEdit } from 'ui/form/inlineEdit/autocompleteEdit/MultipleAutocompleteEdit';
import { mapToAutocompleteOptions } from 'ui/form/autocomplete/Autocomplete.functions';
import { mapToSelectOptions } from 'ui/form/select/Select.mappers';
import { SelectEdit } from 'ui/form/inlineEdit/selectEdit/SelectEdit';
import { countries } from 'config/data/countries';
import { useIndustries } from 'hooks/useIndustries/useIndustries';
import { useUpdateProject } from 'hooks/useUpdateProject/useUpdateProject';
import { useClients } from 'hooks/useClients/useClients';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';

export const ClientDetails = (props: ClientDetailsProps) => {
  const [expanded, setExpanded] = useState(true);
  const { clientData, projectId, disabled } = props;

  const { formatMessage } = useLocale();
  const { data: industries = [] } = useIndustries();
  const { data: clients = [] } = useClients();
  const { projectDetails } = useProjectDetails();
  const { mutateAsync, isLoading } = useUpdateClient(projectId, clientData.id);
  const { mutateAsync: muteAsyncProject, isLoading: isProjectUpdateLoading } = useUpdateProject(projectId);

  const otherClientNames = clients.map((client) => client.name).filter((clientName) => clientName !== clientData.name);

  const updateClientName = async (name: string) => {
    const selectedClient = clients.find((client) => client.name === name);
    muteAsyncProject({ name: projectDetails.name, clientId: selectedClient?.id });
  };

  return (
    <Accordion
      summary={formatMessage({ id: AppMessages['projectDetails.accordions.details.client'] })}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
    >
      <Accordion.Details>
        <Stack gap={1}>
          <SelectEdit
            name="name"
            value={clientData.name}
            label="projectDetails.accordions.details.client.name"
            defaultValues={{ name: clientData.name }}
            onSubmit={(client) => updateClientName(client.name)}
            options={mapToSelectOptions(otherClientNames)}
            loading={isProjectUpdateLoading}
            disabled={disabled}
          />
          <MultipleAutocompleteEdit
            name="industries"
            value={clientData.industries}
            label="projectDetails.accordions.details.client.industries"
            defaultValues={{ industries: clientData.industries }}
            onSubmit={mutateAsync}
            options={mapToAutocompleteOptions(industries)}
            isFreeSolo
            loading={isLoading}
            disabled={true}
          />
          <SelectEdit
            name="country"
            value={clientData.country}
            label="projectDetails.accordions.details.client.country"
            defaultValues={{ country: clientData.country }}
            onSubmit={mutateAsync}
            options={mapToSelectOptions(countries)}
            loading={isLoading}
            disabled={true}
          />
          <TextFieldEdit
            name="accountNumber"
            value={clientData.accountNumber || ''}
            label="projectDetails.accordions.details.client.accountNumber"
            defaultValues={{ accountNumber: clientData.accountNumber }}
            onSubmit={mutateAsync}
            loading={isLoading}
            disabled={true}
          />
        </Stack>
      </Accordion.Details>
    </Accordion>
  );
};
