import { Styles } from 'styles/theme';

export const row: Styles = {
  textDecoration: 'none',
  cursor: 'pointer',
  '&&:hover': {
    backgroundColor: (theme) => theme.palette.accent.blue.pale,
  },
  '&& .MuiTableCell-root': {
    paddingY: 1,
  },
  '&& .MuiTableCell-body': {
    whiteSpace: 'wrap',
  },
};

export const statusInfo: Styles = {
  mt: 0.5,
};

export const buttonSkeleton: Styles = {
  ml: '1rem',
};

export const workstatementNumberSkeleton: Styles = {
  height: 66,
  width: 471,
};
