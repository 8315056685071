import { Styles } from 'styles/theme';

export const cardWrapper: Styles = {
  flex: 1,
  background: 'white',
  padding: 5,
  borderRadius: 0,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'border.light',
};
