import { useQueryClient } from '@tanstack/react-query';

import { createClientMutation } from 'api/actions/createClient/createClientActions';
import { clientsKeys } from 'utils/queryKeys';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

export const useCreateClient = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  return useMutationDomainError(['createClientMutation'], createClientMutation(), {
    onSuccess: () => {
      queryClient.invalidateQueries(clientsKeys.allClients);
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.createClient.error'] }),
  });
};
