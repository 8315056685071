import { useNavigate } from 'react-router-dom';
import { AddIcon } from 'lux/icons';

import { PageHeader } from 'ui/pageHeader/PageHeader';
import { AppRoute } from 'routing/AppRoute.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useTitle } from 'hooks/useTitle/useTitle';
import { AppMessages } from 'i18n/messages';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';

import { DashboardGrid } from './dashboardGrid/DashboardGrid';

export const Dashboard = () => {
  const { formatMessage } = useLocale();
  const { data: user } = useCurrentUser();
  const navigate = useNavigate();

  useTitle(formatMessage({ id: AppMessages['title.dashboard'] }));

  return (
    <>
      <PageHeader
        title={formatMessage({ id: AppMessages['dashboard.header.title'] }, { name: user?.person.firstName })}
        actionTitle={formatMessage({ id: AppMessages['dashboard.header.actionBtn.text'] })}
        icon={<AddIcon />}
        onClick={() => navigate(AppRoute.createProject, { state: { from: AppRoute.dashboard } })}
      />
      <DashboardGrid />
    </>
  );
};
