import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { clientsKeys } from 'utils/queryKeys';
import { getClientsQuery } from 'api/actions/getClients/getClients';
import { GetClientsParams, GetClientsResponse } from 'api/actions/getClients/getClients.types';

export const useClients = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<GetClientsParams, GetClientsResponse>(clientsKeys.allClients, getClientsQuery, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });
};
