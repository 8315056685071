import { WorkStatementAssignment } from 'api/types/BillingSummary.types';
import { TimesheetAssignment } from 'context/projectTimesheet/projectTimesheetDataContext/ProjectTimesheetDataContext.types';

export type WarningMessageProps = {
  assignments: (WorkStatementAssignment | TimesheetAssignment)[] | undefined;
};

export function isTimesheetTeamMember(
  filteredDiscrepancyPeople: (TimesheetAssignment | WorkStatementAssignment)[],
): filteredDiscrepancyPeople is TimesheetAssignment[] {
  return !!filteredDiscrepancyPeople.length && filteredDiscrepancyPeople[0].hasOwnProperty('name');
}

export function isWorkStatementTeamMember(
  filteredDiscrepancyPeople: (TimesheetAssignment | WorkStatementAssignment)[],
): filteredDiscrepancyPeople is WorkStatementAssignment[] {
  return (
    !!filteredDiscrepancyPeople.length &&
    filteredDiscrepancyPeople[0].hasOwnProperty('firstName') &&
    filteredDiscrepancyPeople[0].hasOwnProperty('lastName')
  );
}
