import { Styles } from 'styles/theme';

export const footer: Styles = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingX: 6,
  width: '100%',
  height: 64,
  backgroundColor: 'transparent',
};

export const projectsBackgroundColor: Styles = {
  backgroundColor: 'surface.paper.default',
};
